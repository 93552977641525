import { twMerge as cn } from 'tailwind-merge'

import {
  LEGAL_LINKS,
  PLAYSEE_LINKS,
  REDIRECT_APP_LINK,
  env
} from 'assets/constant'
import PlayseeLogo from 'assets/icon/logo/playsee_logo_new.svg'

const Footer = () => {
  const contents = [
    {
      catalog: 'Playsee',
      links: [
        {
          name: 'Download App',
          href: REDIRECT_APP_LINK
        },
        env !== 'master' && {
          name: 'Playsee Ads',
          href: PLAYSEE_LINKS.business
        },
        {
          name: 'About',
          href: PLAYSEE_LINKS.about
        },
        {
          name: 'Careers',
          href: PLAYSEE_LINKS.careers
        }
      ].filter(Boolean) as { name: string; href: string }[]
    },
    {
      catalog: 'Resources',
      links: [
        {
          name: 'Help Center',
          href: PLAYSEE_LINKS.help
        }
      ]
    },
    {
      catalog: 'Legal',
      links: [
        {
          name: 'Playsee Rules',
          href: LEGAL_LINKS.rules
        },
        {
          name: 'Terms of Service',
          href: LEGAL_LINKS.terms
        },
        {
          name: 'Additional Terms of Service (Business)',
          href: LEGAL_LINKS.adTerms
        },
        {
          name: 'Privacy & Cookies',
          href: LEGAL_LINKS.privacy
        }
      ]
    }
  ]

  return (
    <footer className='border-t border-b border-glass_special-separators homePad:py-[48px] homePad:flex homePad:justify-center'>
      <div className='homePad:flex homePad:px-[64px] homePad:justify-center w-full'>
        {/* Contents part */}
        <div className='flex flex-wrap px-[24px] py-[32px] footer-content-gap homePad:basis-[max-content] homePad:shrink homePad:order-1 homePad:p-0'>
          {contents.map((content, index) => (
            <div
              key={index}
              className={cn('flex flex-col w-max', index === 2 && 'col-span-2')}
            >
              <div className='text-playseeGray-gray3 font-semibold mb-[12px]'>
                {content.catalog}
              </div>
              <div className='flex flex-col space-y-[12px]'>
                {content.links.map((link, linkIndex) => (
                  <a
                    key={linkIndex}
                    href={link.href}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='text-playseeGray-gray1'
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Logo part */}
        <div className='flex mx-[24px] py-[32px] border-t border-glass_special-separators homePad:flex-col homePad:border-0 homePad:m-0 homePad:p-0'>
          <PlayseeLogo className='w-[118px] h-[36px] fill-label-l3' />
          <div className='flex flex-col ml-auto homePad:ml-0 homePad:mt-[24px]'>
            {/* <LanguageSelector /> */}
            <div className='ml-auto mt-[8px] homePad:ml-0 text-playseeGray-gray3'>
              ©{new Date().getFullYear()} Playsee
            </div>
          </div>
        </div>

        {/* Spacing */}
        <div className='hidden homePad:block basis-[640px] shrink-[1000000] grow max-w-[640px] min-w-[64px]'></div>
      </div>
    </footer>
  )
}

export default Footer
