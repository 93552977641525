type Env = 'local' | 'develop' | 'stage' | 'beta' | 'master'
export const env = process.env.NEXT_PUBLIC_ENVIRONMENT as Env

export const GTM_KEY =
  env === 'master'
    ? process.env.NEXT_PUBLIC_GTM_ID_PUBLIC
    : env === 'stage'
    ? process.env.NEXT_PUBLIC_GTM_ID_STAGE
    : ''

const APP_ID = '1466926345'
const APP_NAME = 'com.framy.placey'
export const APP_DOWNLOAD_LINKS = {
  ios: `https://apps.apple.com/app/id${APP_ID}`,
  android: `https://play.google.com/store/apps/details?id=${APP_NAME}`
}

export const SOCIAL_LINKS = {
  twitter: 'https://twitter.com/playseeofficial',
  facebook: 'https://www.facebook.com/playseeofficial',
  linkedin: 'https://www.linkedin.com/company/playseeapp',
  instagram: 'https://www.instagram.com/playseeofficial'
}

type LinkPrefix = 'about' | 'business' | 'help' | 'careers'
type LinkEnv = 'co' | 'beta' | 'dev'
type BuildLink<Prefix extends LinkPrefix> = Record<
  Prefix,
  `https://${Prefix}.playsee.${LinkEnv}/`
>
type Links = BuildLink<'about'> &
  BuildLink<'business'> &
  BuildLink<'help'> &
  BuildLink<'careers'> & { platform: `https://playsee.${LinkEnv}/` }

export const PLAYSEE_LINKS = new Proxy(
  {},
  {
    get: (_, key: LinkPrefix | 'platform') => {
      const suffix = env === 'master' ? 'co' : env === 'beta' ? 'beta' : 'dev'
      const domain = key === 'platform' ? 'playsee' : `${key}.playsee`
      return `https://${domain}.${suffix}/`
    }
  }
) as Links

export const LEGAL_LINKS = {
  rules: `${PLAYSEE_LINKS.platform}rules`,
  privacy: `${PLAYSEE_LINKS.platform}privacy`,
  terms: `${PLAYSEE_LINKS.platform}user-terms`,
  adTerms: `${PLAYSEE_LINKS.platform}ad-terms`
} as const

export const REDIRECT_APP_LINK = 'https://love.playsee.co/'

export const CDN_URL = 'https://storage.googleapis.com/playsee-web'

export const API_PREFIX_BI =
  env === 'master'
    ? process.env.NEXT_PUBLIC_API_PREFIX_BI_PUBLIC
    : process.env.NEXT_PUBLIC_API_PREFIX_BI_STAGE
