import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ElementRef, Fragment, useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'

import { CDN_URL, GTM_KEY } from 'assets/constant'
import CookieHint from 'component/cookie-hint'
import Footer from 'component/footer'
import Header from 'component/header'
import './global.css'

const App = ({ Component, pageProps }: AppProps) => {
  const [leavedTop, setLeavedTop] = useState<boolean>(false)

  const headerRef = useRef<ElementRef<'header'>>(null)
  const landingRef = useRef<ElementRef<'section'>>(null)
  const router = useRouter()

  useEffect(() => {
    const header = headerRef.current
    const landing = landingRef.current
    if (!header || !landing || router.pathname.includes('detail')) return

    const handleScroll = () => {
      if (window.scrollY > landing.offsetHeight - header.offsetHeight) {
        setLeavedTop(true)
      } else {
        setLeavedTop(false)
      }
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [router.pathname])

  useEffect(() => {
    if (GTM_KEY) {
      TagManager.initialize({ gtmId: GTM_KEY })
    }
  }, [])

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
        />
        <title>Playsee Careers | Job Openings</title>
        <meta
          name='description'
          content="Join Playsee to build the world's first location-based social media video app. Learn more about job opportunities."
        />
        <meta property='og:url' content='https://careers.playsee.co/' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Playsee Careers | Job Openings' />
        <meta
          property='og:description'
          content="Join Playsee to build the world's first location-based social media video app. Learn more about job opportunities."
        />
        <meta property='og:image' content={`${CDN_URL}/common/images/playsee_banner_careers.jpg`} />
      </Head>

      <Header
        headerRef={headerRef}
        leavedTop={router.pathname.includes('detail') || leavedTop}
      />
      <Component {...pageProps} landingRef={landingRef} />

      <CookieHint />
      <Footer />
    </Fragment>
  )
}

export default App
