import CookieConsent from 'react-cookie-consent'

import { LEGAL_LINKS } from 'assets/constant'

const CookiesHint = () => {
  return (
    <CookieConsent
      containerClasses='cookies-hint'
      buttonText='Ok'
      disableStyles={true}
      cookieName='pca'
      location='none'
      contentClasses='cookies-text'
      buttonClasses='cookies-ok'
      buttonWrapperClasses=''
    >
      Playsee uses cookies to improve your experience on our site. By continuing
      to use or clicking OK, you accept our
      <a
        href={`${LEGAL_LINKS.privacy}#cookies`}
        title='Cookie Policy'
        target='_blank'
        rel='noopener noreferrer'
        className='px-[2px] font-semibold'
      >
        Cookie Policy
      </a>
    </CookieConsent>
  )
}

export default CookiesHint
